import React, { useContext, Fragment } from "react"
import { navigate } from "gatsby"

import { AppContext } from "../../../context/AppContext"

const ReceiptTableRow = ({ receipt, receipts }) => {
  const { state, dispatch } = useContext(AppContext)

  let { salesInvoiceNumber, purchaseDate, lineItems } = receipt
  let purchaseDateInfo = `${purchaseDate.month.value} ${purchaseDate.date.value} ${purchaseDate.year}`

  const handleEditReceipt = () => {
    dispatch({
      type: "SAVE_RECEIPT_UPLOAD_STATE",
      payload: receipt,
    })
    navigate("/giotrif/medicine-availment/receipt/upload")
  }

  const handleDeleteReceipt = () => {
    let receiptToBeDeleted = receipts.filter(
      receiptDetail => receiptDetail?.salesInvoiceNumber === salesInvoiceNumber
    )
    let documentFileName = receiptToBeDeleted?.[0]?.document?.[0]?.filename

    let tempDocuments = [...state?.documents]
    tempDocuments = tempDocuments.filter(
      document => document.name !== documentFileName
    )

    dispatch({
      type: "SAVE_DOCUMENTS",
      payload: [...tempDocuments],
    })

    let tempReceipts = receipts.filter(
      receiptDetail => receiptDetail?.salesInvoiceNumber !== salesInvoiceNumber
    )

    dispatch({
      type: "SAVE_MEDICINE_AVAILMENT",
      payload: { receipts: tempReceipts },
    })
  }

  let isDeleteAlreadyDisplayed = false
  let validLineItems = lineItems.filter(item => item.isOrdered.length > 0)

  const RowActionButtons = () => {
    if (!isDeleteAlreadyDisplayed) {
      isDeleteAlreadyDisplayed = true
      return (
        <Fragment>
          {/* <button class="button is-small mr-1" onClick={handleEditReceipt}>
            Edit
          </button> */}
          <button
            class="button is-small is-danger"
            onClick={handleDeleteReceipt}
          >
            Delete
          </button>
        </Fragment>
      )
    }
    return null
  }

  return (
    <Fragment>
      {lineItems.map((item, index) => {
        if (item.isOrdered.length > 0) {
          return (
            <tr>
              <td>{salesInvoiceNumber}</td>
              <td>{purchaseDateInfo}</td>
              <td>{item.lineItem}</td>
              <td className="has-text-right">{item.tabletsOrdered}</td>
              <td rowSpan={validLineItems.length} className="is-vcentered">
                <RowActionButtons />
              </td>
            </tr>
          )
        }
      })}
    </Fragment>
  )
}

export default ReceiptTableRow
