import React, { Fragment } from "react"
import { navigate } from "gatsby"

import Button from "elements/Button"

const EncodeTableHeader = ({ className }) => {
  return (
    <Fragment>
      <div className="is-flex is-justify-content-flex-end is-hidden-mobile">
        <Button
          color="primary"
          onClick={() => {
            navigate("/giotrif/medicine-availment/receipt/upload")
          }}
        >
          + Add New Entry
        </Button>
      </div>
      <div className="is-hidden-tablet is-hidden-desktop">
        <Button
          color="primary"
          onClick={() => {
            navigate("/giotrif/medicine-availment/receipt/upload")
          }}
          isFullwidth
        >
          + Add New Entry
        </Button>
      </div>
    </Fragment>
  )
}

export default EncodeTableHeader
