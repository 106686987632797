import React, { Fragment, useContext, useEffect } from "react"

import Table from "elements/Table"
import ReceiptTableRow from "./ReceiptTableRow"
import EncodeTableHeader from "./EncodeTableHeader"
import ReceiptTableRowMobile from "./ReceiptTableRowMobile"

import { AppContext } from "../../../context/AppContext"

const EncodeTable = () => {
  const { state, dispatch } = useContext(AppContext)
  let receipts = state.medicineAvailment.receipts

  return (
    <Fragment>
      <EncodeTableHeader />
      <Table
        headers={[
          "Sales Invoice Number",
          "Purchase Date",
          "Line Item",
          "Tablets Ordered",
          "",
        ]}
        className="my-1 is-hidden-mobile"
      >
        {receipts.map(receipt => (
          <ReceiptTableRow receipt={receipt} receipts={receipts} />
        ))}
      </Table>
      <div className="my-1 is-hidden-desktop is-hidden-tablet">
        {receipts.map(receipt => (
          <ReceiptTableRowMobile receipt={receipt} receipts={receipts} />
        ))}
      </div>
    </Fragment>
  )
}

export default EncodeTable
