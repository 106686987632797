import React from "react"
import classNames from "classnames"

const Table = ({ headers, children, className, isHeaderCentered }) => (
  <div className="table-container">
    <table className={classNames("table is-fullwidth", className || "")}>
      <thead className={classNames({ "has-text-centered": isHeaderCentered })}>
        <tr>
          {headers.map(header => (
            <th>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  </div>
)

export default Table
