import React, { useContext } from "react"

import Layout from "../Layout/Layout"
import Container from "../Layout/Container"
import EncodeTable from "./components/EncodeTable"
import ActionButtons from "elements/ActionButtons"
import EncodeInformation from "./components/EncodeInformation"

import { AppContext } from "../../context/AppContext"

const Receipt = () => {
  const { state } = useContext(AppContext)

  return (
    <Layout
      title="Upload Proof of Purchase"
      seoTitle="Upload Proof of Purchase"
      subtitle="To claim your medicine assistance, please upload valid sales invoices for your past orders of Afatinib (Giotrif)."
    >
      <Container isCentered desktop={8} fullhd={8}>
        <EncodeInformation />
        <EncodeTable />
        <ActionButtons
          back={{
            label: "Back",
            link: "/giotrif/medicine-availment/upload",
          }}
          next={{
            label: "Next",
            link: "/giotrif/medicine-availment/delivery-details",
            disabled: state?.medicineAvailment?.receipts.length === 0,
          }}
        />
      </Container>
    </Layout>
  )
}

export default Receipt
