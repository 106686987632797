import React, { Fragment } from "react"

import Message from "elements/Message"

const EncodeInformation = () => {
  return (
    <Fragment>
      <Message color="primary" className="mb-4 mb-2-mobile">
        <p className="has-text-black is-size-6">
          Click 'Add New Entry' for each sales invoice you upload. Please input
          all the items indicated on this invoice. Only items encoded will be
          used to calculate your medicine assistance.
        </p>
      </Message>
    </Fragment>
  )
}

export default EncodeInformation
