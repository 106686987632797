import React, { useContext, Fragment } from "react"
import classNames from "classnames"
import { navigate } from "gatsby"

import styles from "./utils/receipt.module.scss"
import { AppContext } from "../../../context/AppContext"

const ReceiptTableRowMobile = ({ receipt, receipts }) => {
  const { state, dispatch } = useContext(AppContext)

  let { salesInvoiceNumber, purchaseDate, lineItems } = receipt
  let purchaseDateInfo = `${purchaseDate.month.value} ${purchaseDate.date.value} ${purchaseDate.year}`

  const handleEditReceipt = () => {
    dispatch({
      type: "SAVE_RECEIPT_UPLOAD_STATE",
      payload: receipt,
    })
    navigate("/giotrif/medicine-availment/receipt/upload")
  }

  const handleDeleteReceipt = () => {
    let receiptToBeDeleted = receipts.filter(
      receiptDetail => receiptDetail?.salesInvoiceNumber === salesInvoiceNumber
    )
    let documentFileName = receiptToBeDeleted?.[0]?.document?.[0]?.filename

    let tempDocuments = [...state?.documents]
    tempDocuments = tempDocuments.filter(
      document => document.name !== documentFileName
    )

    dispatch({
      type: "SAVE_DOCUMENTS",
      payload: [...tempDocuments],
    })

    let tempReceipts = receipts.filter(
      receiptDetail => receiptDetail?.salesInvoiceNumber !== salesInvoiceNumber
    )

    dispatch({
      type: "SAVE_MEDICINE_AVAILMENT",
      payload: { receipts: tempReceipts },
    })
  }

  return (
    <Fragment>
      <div className={classNames("card", styles["cardRow"])}>
        <div className="card-content">
          <div>
            <small className="is-block has-text-weight-bold">
              Sales Invoice Number
            </small>
            <p className="is-size-6">{salesInvoiceNumber}</p>
          </div>
          <div>
            <small className="is-block mt-1 has-text-weight-bold">
              Purchase Date
            </small>
            <p className="is-size-6">{purchaseDateInfo}</p>
          </div>
          <div>
            <small className="is-block mt-1 has-text-weight-bold">
              Line Items
            </small>
            {lineItems.map((item, index) => {
              if (item.isOrdered.length > 0)
                return (
                  <p className="is-size-6">
                    {item.lineItem}, # {item.tabletsOrdered}
                  </p>
                )
            })}
          </div>
          <button
            class="button mt-2 is-small is-danger"
            onClick={handleDeleteReceipt}
          >
            Delete
          </button>
        </div>
      </div>
    </Fragment>
  )
}

export default ReceiptTableRowMobile
